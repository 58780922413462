import { MagnificentProvider } from "contexts";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<MagnificentProvider>
			<App />
		</MagnificentProvider>
	</React.StrictMode>
);
