export const ROUTES = {
	home: "/",
	category: "/category",
	question: "/category/:categoryId",
	login: "/login",
	account: "/account",
	leaderboard: "/leaderboard",
	settings: "/settings",
	notifications: "/notifications",
	support: "/settings",
	signup: "/signup",
	profile: "/profile",
	loader: "loader",
	notFound: "pagenotfound",
	unknown: "*",
	localStorageKey: "magnificent-quiz-token",
	quizQuestionData: "quiz-question-data",
};
